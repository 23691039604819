button {
    font-family: 'Courier New', Courier, monospace;
    font-size: x-large;
    width: 150px;
    height: 75px;
    padding: 5px;
    text-align: center;
}

.blue {
    background-color: blue;
    color: white;
}

.red {
    background-color: red;
    color: white;
}

.neutral {
    background-color: wheat;
    color: black;
}

.death {
    background-color: black;
    color: white;
}