*, *:before, *:after {
    box-sizing: border-box;
}

input {
    padding: 10px 20px 10px 20px;
    box-shadow:0 0 15px 4px rgba(0,0,0,0.06);
    font-size: 16px;
    width: 200px;
}

.joinRoom {
    font: bold 16px Arial;
    text-decoration: none;
    background-color: blue;
    color:white;
    padding: 10px 40px 10px 40px;
    border-top: 1px solid #CCCCCC;
    border-right: 1px solid #333333;
    border-bottom: 1px solid #333333;
    border-left: 1px solid #CCCCCC;
    box-sizing: border-box;
}

.createRoom {
    font: bold 16px Arial;
    text-decoration: none;
    background-color: green;
    color: white;
    padding: 10px 30px 10px 30px;
    border-top: 1px solid #CCCCCC;
    border-right: 1px solid #333333;
    border-bottom: 1px solid #333333;
    border-left: 1px solid #CCCCCC;
    box-sizing: border-box;
}